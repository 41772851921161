import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Data,
  NavigationEnd,
  Router,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Breadcrumb } from '../components/breadcrumb/models/breadcrumb.model';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();
  private currentUrl = new BehaviorSubject<string | null>(null);
  public sharedUrl = this.currentUrl.asObservable();

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: Breadcrumb[] = [];
        this.addBreadcrumb(root, breadcrumbs);

        this._breadcrumbs$.next(breadcrumbs);
      });
  }

  private addBreadcrumb(
    route: ActivatedRouteSnapshot | null,
    breadcrumbs: Breadcrumb[]
  ) {
    if (route) {
      if (route.data.url) {
        this.currentUrl.next(route.data.url);
      }
      if (route.data.breadcrumb) {
        const breadcrumb = {
          label: this.getLabel(route.data),
        };
        if (Array.isArray(route.data.breadcrumb)) {
          for (const iterator of breadcrumb.label) {
            breadcrumbs.push({ label: iterator });
          }
        } else {
          breadcrumbs.push(breadcrumb);
        }
      }
      this.addBreadcrumb(route.firstChild, breadcrumbs);
    }
  }

  private getLabel(data: Data) {
    return typeof data.breadcrumb === 'function'
      ? data.breadcrumb(data)
      : data.breadcrumb;
  }
}
