import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'src/app/services/message.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorIntercept implements HttpInterceptor {
  constructor(
    private messageService: MessageService,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error: ${error?.error?.message}`;
        } else {
          errorMessage = `Error: ${error?.error?.message}`;
        }
        if (
          error.status === 400 &&
          (error?.error?.message === 'Invalid JWT' ||
            error?.error?.message === 'Invalid Refresh Token')
        ) {
          return this.handleExpiresJWT(request, next);
        }
        if (error.status === 500) {
          this.messageService.openSnackBar('Something went wrong!');
          return this.handleExpiresJWT(request, next);
        }
        if (!errorMessage || errorMessage.includes('undefined')) {
          errorMessage = 'Session was expired!';
        }
        this.messageService.openSnackBar(errorMessage);
        return throwError(error);
      })
    );
  }

  private handleExpiresJWT(request: HttpRequest<any>, next: HttpHandler) {
    this.authService.logoutMode();
    return next.handle(request);
  }
}
