import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ConfirmComponent } from '../components/confirm/confirm.component';
import { ConfirmDialogData } from '../components/confirm/confirm.models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private radioOption: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) {}

  public setRadioOption(option: string): void {
    this.radioOption.next(option);
  }

  getRadioOption(): string {
    return this.radioOption.value;
  }

  public openDialog<T, R>(
    component: ComponentType<T>,
    data?: any,
    width?: string
  ): MatDialogRef<T, R> {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();

    dialogConfig.data = data;
    dialogConfig.width = width;
    dialogConfig.disableClose = true;

    return this.dialog.open(component, dialogConfig);
  }

  public async confirm(
    title: string,
    message: string,
    confirmButtonText?: string,
    followingAction?: boolean,
    showCancel?: boolean
  ): Promise<boolean> {
    const data: ConfirmDialogData = new ConfirmDialogData();
    data.title = title;
    data.message = message;
    data.confirmButtonText = confirmButtonText;
    data.followingAction = followingAction;
    data.showCancel = showCancel;

    const dialogRef: MatDialogRef<ConfirmComponent, string> = this.openDialog<
      ConfirmComponent,
      string
    >(ConfirmComponent, data);

    const res = await dialogRef.afterClosed().toPromise();
    return res === 'true';
  }

  public openSnackBar(
    message: string,
    duration: number = 2000,
    horizontalPosition: MatSnackBarHorizontalPosition = 'right',
    verticalPosition: MatSnackBarVerticalPosition = 'top',
    action: string = ''
  ): void {
    this.snackBar.open(message, action, {
      duration: duration,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }
}
