import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'src/app/services/message.service';
import { ConfirmDialogData } from './confirm.models';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.sass'],
})
export class ConfirmComponent {
  @Input() public title: string | undefined = '';
  @Input() public message: string | undefined = '';
  @Input() public followingAction: boolean | undefined = false;
  @Input() public showCancel: boolean | undefined = true;

  @Input() public confirmButtonText: string | undefined = 'Confirm';

  followingActionMessage = '';
  radioOptions: string[] = [];

  constructor(
    private msgService: MessageService,
    @Inject(MAT_DIALOG_DATA) private data?: ConfirmDialogData
  ) {
    this.setInputs();
  }

  submit(): void {
    if (this.followingAction) {
      this.msgService.setRadioOption(this.followingActionMessage);
    }
  }

  private setInputs(): void {
    if (this.data === undefined) {
      return;
    }

    this.title = this.data.title;
    this.message = this.data.message;
    this.confirmButtonText = this.data.confirmButtonText;
    this.followingAction = this.data.followingAction;
    if (this.data?.showCancel === false) {
      this.showCancel = false;
    }
    if (this.followingAction) {
      this.radioOptions =
        this.confirmButtonText === 'DELETE'
          ? ['Delete this', 'Delete this and following', 'Delete all']
          : ['Edit this', 'Edit this and following', 'Edit all'];
    }
    this.followingActionMessage = this.radioOptions[0];
  }
}
