import { Routes } from '@angular/router';
import { AuthGuard } from './modules/auth/guards/auth.guard';
import { LoggedOutGuard } from './modules/auth/guards/logged-out.guard';
import { RoleGuard } from './modules/auth/guards/role-guard';

export const routes: Routes = [
  {
    path: '',
    canActivate: [LoggedOutGuard],
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./components/shared-components.module').then(
        (m) => m.SharedComponentsModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard, RoleGuard],
    data: { module: 'FINANCE' },
    loadChildren: () =>
      import('./modules/finance/finance.module').then((m) => m.FinanceModule),
  },
  {
    path: '',
    canActivate: [AuthGuard, RoleGuard],
    data: { module: 'EMAILS' },
    loadChildren: () =>
      import('./modules/mailing/mailing.module').then((m) => m.MailingsModule),
  },
  {
    path: '',
    canActivate: [AuthGuard, RoleGuard],
    data: { module: 'GENERAL_COSTS' },
    loadChildren: () =>
      import('./modules/general-costs/general-costs.module').then(
        (m) => m.GeneralCostsModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard, RoleGuard],
    data: { module: 'TIME_ENTRY' },
    loadChildren: () =>
      import('./modules/timeentry/timeentry.module').then(
        (m) => m.TimeEntryModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard, RoleGuard],
    data: { module: 'PROJECTS' },
    loadChildren: () =>
      import('./modules/projects/projects.module').then(
        (m) => m.ProjectsModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard, RoleGuard],
    data: { module: 'CRM' },
    loadChildren: () =>
      import('./modules/crm/crm.module').then((m) => m.CRMModule),
  },
  {
    path: '',
    canActivate: [AuthGuard, RoleGuard],
    data: { module: 'SETTINGS' },
    loadChildren: () =>
      import('./modules/staff/staff.module').then((m) => m.StaffModule),
  },
  {
    path: '',
    canActivate: [AuthGuard, RoleGuard],
    data: { module: 'ADMINISTRATION' },
    loadChildren: () =>
      import('./modules/admin-settings/admin-settings.module').then(
        (m) => m.AdminSettingsModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard, RoleGuard],
    data: { module: 'REPORTS' },
    loadChildren: () =>
      import('./modules/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: '',
    canActivate: [AuthGuard, RoleGuard],
    data: { module: 'SETTINGS' },
    loadChildren: () =>
      import('./modules/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard, RoleGuard],
    data: { module: 'QUOTE' },
    loadChildren: () =>
      import('./modules/quotes/quotes.module').then((m) => m.QuotesModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/notifications/notifications.module').then(
        (m) => m.NotificationsModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
