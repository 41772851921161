import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AuthApiService } from './auth-api.service';
import { AuthHelperService } from './auth.helper';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    AuthApiService,
    AuthHelperService
  ]
})
export class AuthServicesModule { }
