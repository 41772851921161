import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/services/api.base';

@Injectable({
  providedIn: 'root',
})
export class AuthHelperService extends ApiService {
  public modules: string[] = [
    'QUOTE',
    'QUOTE_SIGN_OFF',
    'FINANCE',
    'PROJECTS',
    'REPORTS',
    'SETTINGS',
    'ADMINISTRATION',
    'STAFF',
    'TIME_ENTRY',
    'CRM',
    'GENERAL_COSTS',
    'EMAILS',
    'PERMISSIONS',
  ];
  private userRole: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(
    []
  );
  public sharedUserRoles = this.userRole.asObservable();

  private userId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public sharedUserId = this.userId.asObservable();

  private userModules: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);
  public sharedUserModules = this.userModules.asObservable();

  constructor(http: HttpClient) {
    super(http);
  }

  public setUserId(id: string): void {
    this.userId.next(id);
  }

  public currentUserModules(): string[] {
    return this.userModules.getValue();
  }

  public setUserModules(userModules: string[]): void {
    this.userModules.next(userModules);
  }

  public setUserRoles(roles: string[]): void {
    this.userRole.next(roles);
  }
}
