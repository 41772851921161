import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StaffApiService } from '../../staff/services/staff-api.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  public modules: string[] = [];
  public roles: string[] = [];

  constructor(private router: Router, private staffService: StaffApiService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.staffService.getCurrentUser().pipe(
      map((user) => {
        const currentModule = route.data.module as string;
        if (user?.systemModules?.includes(currentModule)) {
          return true;
        } else {
          return this.router.parseUrl('/timeentry');
        }
      })
    );
  }
}
