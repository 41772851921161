<h3 mat-dialog-title>{{title}}</h3>

<div mat-dialog-content>
  <span>{{message}}</span>
</div>

<mat-radio-group
  aria-labelledby="radio-group-label"
  class="radio-group"
  [(ngModel)]="followingActionMessage"
  *ngIf="followingAction">
  <mat-radio-button class="radio-button" *ngFor="let option of radioOptions" [value]="option">
    {{ option }}
  </mat-radio-button>
</mat-radio-group>

<div mat-dialog-actions end>
  <button mat-flat-button mat-dialog-close class="btn" *ngIf="showCancel">CANCEL</button>
  <button mat-flat-button class="btn-delete" mat-dialog-close="true" (click)="submit()">{{ confirmButtonText }}</button>
</div>
