import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.base';
import { AuthStorageService } from 'src/app/services/auth-storage.service';
import { LoginRequest } from '../models/login-request.model';
import { LoginResponse } from '../models/login-response.model';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService extends ApiService {
  constructor(
    http: HttpClient,
    private authStorageService: AuthStorageService
  ) {
    super(http);
  }

  public login(userLogin: LoginRequest): Observable<LoginResponse> {
    const url: string = `${this.endpoint}users/auth/login`;

    return this.http.post<LoginResponse>(url, userLogin);
  }

  public forgotPassword(email: string): Observable<null> {
    const url: string = `${this.endpoint}users/auth/forgot-password?email=${email}`;

    return this.http.post<null>(url, null);
  }

  public resetPassword(newPassword: string, token: string): Observable<null> {
    const url: string = `${this.endpoint}users/auth/reset-password?token=${token}`;

    return this.http.post<null>(url, { newPassword });
  }

  public refreshToken(): Observable<LoginResponse> {
    const refreshToken: string | null =
      this.authStorageService.getRefreshToken();
    const url: string = `${this.endpoint}users/auth/refresh?token=${refreshToken}`;

    return this.http.post<LoginResponse>(url, null);
  }

  public logout(): Observable<object> {
    const url: string = `${this.endpoint}users/auth/logout`;

    return this.http.post<object>(url, null);
  }
}
