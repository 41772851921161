import { Injectable } from '@angular/core';
import { AuthStorageService } from './auth-storage.service';
import { LOGOUT_REDIRECT } from '../modules/auth/constants/auth-constants';
import { Router } from '@angular/router';
import { AuthApiService } from '../modules/auth/services/auth-api.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { LoginRequest } from '../modules/auth/models/login-request.model';
import { LoginResponse } from '../modules/auth/models/login-response.model';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private authStorageService: AuthStorageService,
    private router: Router,
    private authApiService: AuthApiService
  ) {}

  public setRedirectUrl(url: string): void {
    this.authStorageService.setRedirectUrl(url);
  }

  public getRedirectUrl(): string | null {
    return this.authStorageService.getRedirectUrl();
  }

  public removeRedirectUrl(): void {
    this.authStorageService.removeRedirectUrl();
  }

  public isAuthenticated(): boolean {
    const isTokenExist = this.authStorageService.getToken() ? true : false;

    return isTokenExist;
  }

  public login(loginData: LoginRequest): Observable<void> {
    return this.authApiService.login(loginData).pipe(
      map((response: LoginResponse) => {
        this.successAuth(response);
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public successAuth(response: LoginResponse): void {
    this.authStorageService.setToken(response.accessToken);
    this.authStorageService.setRefreshToken(response.refreshToken);
    this.authStorageService.setUserRole(JSON.stringify(response.roles));
  }

  public logout(): Observable<void> {
    return this.authApiService.logout().pipe(
      map(() => {
        this.logoutMode();
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public logoutMode(): void {
    this.authStorageService.clearAuthStorage();
    this.router.navigate([LOGOUT_REDIRECT]);
  }
}
