import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.base';
import { CreateUser } from '../../profile/models/create-user.model';
import { EditUser } from '../../profile/models/edit-user.model';
import { UserModel } from '../../profile/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class StaffApiService extends ApiService {
  constructor(http: HttpClient) {
    super(http);
  }

  public getUsers(): Observable<UserModel[]> {
    const url: string = `${this.endpoint}users`;

    return this.http.get<UserModel[]>(url);
  }

  public getArchivedUsers(): Observable<UserModel[]> {
    const url: string = `${this.endpoint}users/archived`;

    return this.http.get<UserModel[]>(url);
  }

  public getProjectManagers(): Observable<UserModel[]> {
    const role = 'ROLE_PROJECT_MANAGER';
    const url = `${this.endpoint}users/search?role=${role}`;

    return this.http.get<UserModel[]>(url);
  }

  public getGeneralProjectManagers(): Observable<UserModel[]> {
    const url = `${this.endpoint}users/project-managers`;

    return this.http.get<UserModel[]>(url);
  }

  public searchUsers(
    filter: string,
    isArchived: boolean
  ): Observable<UserModel[]> {
    const specialCharacters: string[] = [
      '%',
      '^',
      '#',
      '*',
      '(',
      ')',
      '|',
      '[',
      ']',
      '{',
      '}',
      '/',
      '`',
      '\\',
    ];
    filter = filter.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
    filter = filter.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
    const find = filter.split('').map((char) => {
      if (specialCharacters.includes(char)) {
        return '';
      }
      return char;
    });
    filter = find.join('');
    let url = `${this.endpoint}users/search?isArchived=${isArchived}`;
    if (filter) {
      url = `${this.endpoint}users/search?filter=${filter}&isArchived=${isArchived}`;
    }

    return this.http.get<UserModel[]>(url);
  }

  public getPublicUserById(userId: string): Observable<UserModel> {
    const url: string = `${this.endpoint}users/${userId}/public`;

    return this.http.get<UserModel>(url);
  }

  public getUserById(userId: string): Observable<UserModel> {
    const url: string = `${this.endpoint}users/${userId}`;

    return this.http.get<UserModel>(url);
  }

  public getCurrentUser(): Observable<UserModel> {
    const url: string = `${this.endpoint}users/me`;

    return this.http.get<UserModel>(url);
  }

  public createUser(userData: CreateUser): Observable<UserModel> {
    const url: string = `${this.endpoint}users`;

    return this.http.post<UserModel>(url, userData);
  }

  public editUser(userData: EditUser, userId: string): Observable<UserModel> {
    const url: string = `${this.endpoint}users/${userId}`;

    return this.http.post<UserModel>(url, userData);
  }

  public changeRoleForUser(
    modules: string[],
    role: string | undefined,
    userId: string
  ): Observable<null> {
    const url: string = `${this.endpoint}users/${userId}/permissions`;

    return this.http.put<null>(url, { modules, role });
  }

  public restoreUser(userId: string): Observable<UserModel> {
    const url: string = `${this.endpoint}users/${userId}/restore`;

    return this.http.post<UserModel>(url, null);
  }

  public archiveUser(userId: string): Observable<UserModel> {
    const url: string = `${this.endpoint}users/${userId}/archive`;

    return this.http.post<UserModel>(url, null);
  }

  public getUsersSignoff(): Observable<UserModel[]> {
    const url: string = `${this.endpoint}users/search/by-modules`;

    return this.http.post<UserModel[]>(url, {
      systemModules: [
        "QUOTE_SIGN_OFF"
      ]
    });
  }
}
