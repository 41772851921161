import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService {
  private userKey: string = 'user';
  private tokenKeyStorage: string = 'token';
  private executorHoursKeyStorage: string = 'executor_hours';
  private refreshTokenKeyStorage: string = 'refreshToken';
  private roleKeyStorage: string = 'role';
  private redirectUrl: string = 'redirect_url';

  constructor(private localStorageService: LocalStorageService) {}

  public getToken(): string | null {
    return this.localStorageService.get(this.tokenKeyStorage);
  }

  public setToken(value: string): void {
    this.localStorageService.set(this.tokenKeyStorage, value);
  }

  public getRedirectUrl(): string | null {
    return this.localStorageService.get(this.redirectUrl);
  }

  public setRedirectUrl(value: string): void {
    this.localStorageService.set(this.redirectUrl, value);
  }

  public removeRedirectUrl(): void {
    this.localStorageService.remove(this.redirectUrl);
  }

  public getExecutorsHoursData(): string | null {
    return this.localStorageService.get(this.executorHoursKeyStorage);
  }

  public setExecutorsHoursData(value: string): void {
    this.localStorageService.set(this.executorHoursKeyStorage, value);
  }

  public removeExecutorsHoursData(): void {
    this.localStorageService.remove(this.executorHoursKeyStorage);
  }

  public getUserRole(): string | null {
    return this.localStorageService.get(this.roleKeyStorage);
  }

  public setUserRole(value: string): void {
    this.localStorageService.set(this.roleKeyStorage, value);
  }

  public clearAuthStorage(): void {
    this.localStorageService.clear();
  }

  public getRefreshToken(): string | null {
    return this.localStorageService.get(this.refreshTokenKeyStorage);
  }

  public setRefreshToken(value: string): void {
    this.localStorageService.set(this.refreshTokenKeyStorage, value);
  }

  public setCurrentUser(user: any): void {
    this.localStorageService.set(this.userKey, JSON.stringify(user));
  }
}
